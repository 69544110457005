import * as React from "react";
import "../components/layout.css";
import { Footer } from "../components/footer";
import { PageLayout } from "../components/layout";
import { Blockquote, H1, H2, Item, Lead, Link, List, Paragraph } from "../components/typography2";
import { Inset } from "../components/layout";

const ImprintPage = () => {
    return (
        <PageLayout>
            <Inset>
                <H1>Imprint</H1>
                <H2>Name and address of the provider</H2>
                <Blockquote>
                    Skyward Assembly GmbH<br />
                    Carlsgrüner Weg 15b<br />
                    95192 Lichtenberg<br />
                    Germany<br />
                </Blockquote>
                <H2>Contact email</H2>
                <Paragraph><Link to="mailto:studio@skywardassembly.com">studio@skywardassembly.com</Link></Paragraph>
                <H2>Authorized representatives</H2>
                <List>
                    <Item><Lead>Dennis Sasse</Lead></Item>
                    <Item><Lead>Andreas Fischer</Lead></Item>
                </List>
                <H2>Commercial register</H2>
                <Blockquote>
                    Amtsgericht Hof<br />
                    Berliner Platz 1<br />
                    95030 Hof<br />
                    Germany<br />
                </Blockquote>
                <H2>Registration number</H2>
                <Lead>HRB 6542</Lead>
                <H2>VAT ID / Ust Id</H2>
                <Lead>DE353619390</Lead>
            </Inset>
            <Footer />
        </PageLayout>
    )
};

export default ImprintPage;
